<template>
  <b-card
    img-src="media/create-page.jpg"
    img-alt="Create a Tribute Page"
  >
    <center><a class="btn-lg btn-primary" :href="url">{{data.button}}</a></center>
  </b-card>
</template>

<script>
export default {
  name: 'CreatePage',

  props: ['data'],

  data () {
    return {
      url: 'http://www.justgiving.com/fundraising-page/creation/?cid=' + process.env.VUE_APP_CHARITY_ID + '&eid=' + process.env.VUE_APP_EVENT_ID
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
