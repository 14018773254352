// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import VueTimeago from 'vue-timeago'
import Axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import Nl2br from 'vue-nl2br'
import VueAnalytics from 'vue-analytics'
import TextHighlight from 'vue-text-highlight';
import './assets/custom.scss'

Vue.prototype.$http = Axios

Vue.config.productionTip = false

Vue.component('nl2br', Nl2br)
Vue.component('text-highlight', TextHighlight);

Vue.use(BootstrapVue)

Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    'zh-CN': require('date-fns/locale/zh_cn'),
    ja: require('date-fns/locale/ja')
  }
})/* eslint-disable no-new */

Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_ID
  // disabled: true,
})

new Vue({
  el: '#app',
  components: { App },
  template: '<App/>'
})
