<template>
  <div id="app">
    <main role="main">
          <Home/>
    </main>
  </div>
</template>

<script>
// import Navigation from '@/components/Navigation'
import Home from '@/components/Home'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
main {
  padding-top: 25px;
}

i.upvote, i.downvote {
  cursor: pointer;
}

body {
  background-color: #ddd;
}
</style>
