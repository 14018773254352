<template>
  <b-container id="header">
    <b-row>
      <b-col>
        <a :href="data.url"><b-img :src="data.logo" class="logo"></b-img></a>
      </b-col>
      <b-col class="text-right donate">
        <a :href="data.donation_link" class="btn-primary btn-lg" v-html="donation"></a>
        <h2 v-html="data.tagline"></h2>
        <!-- <b-button class="float-right" size="lg" variant="danger">Make a Donation</b-button> -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Header',
  props: ['data'],

  data () {
    return {
    }
  },

  computed: {
    donation () {
      return this.data.donation.replace(" ", "&nbsp;")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
