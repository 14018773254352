<template>
  <b-container id="impacts">
    <b-row align-v="center">
      <b-col md="6" sm="12">
        <TwentyTwenty  v-if="data.after" :before="data.before" :after="data.after" :beforeLabel="data.before_label" :afterLabel="data.after_label" />
        <b-img v-else :src="data.before" fluid />
      </b-col>

      <b-col md="6">
        <h2>{{data.title}}</h2>
        <nl2br tag="p" :text="data.blurb" />
        <a class="btn btn-primary" :href="data.url">{{data.button}}</a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import 'vue-twentytwenty/dist/vue-twentytwenty.css';
import TwentyTwenty from 'vue-twentytwenty';

export default {
  name: 'Impacts',

  props: ['data'],

  components: {
    TwentyTwenty
  },

  data () {
    return {
      url: this.data.before_image
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
