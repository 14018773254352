<template>
  <div id="footer">
    <b-container>
      <b-row>
        <b-col md="3" sm="6" class="social text-center text-lg-left">
          <h4>Follow us</h4>
          <ul class="socialIcons">
            <li v-for="item in socials" :key="item.title">
              <a :class="item.class" :href="item.href" :alt="item.alt">{{item.name}}</a>
            </li>
          </ul>
          <b-img src="fundraising-regulator.png" fluid class="fr-regulator"></b-img>
        </b-col>
        <b-col md="9" sm="6" class="links text-center text-md-right">
          <ul class="links">
            <li v-for="item in data.links" :key="item.name">
              <a :href="item.url">{{item.name}}</a>
            </li>
          </ul>
          <p class="registered">{{data.charity}}</p>
        </b-col>
      </b-row>
    </b-container>
    <cookie-law v-on:accept="enableTracking()" />
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'Footer',

  props: ['data'],

  components: {
    CookieLaw
  },

  data () {
    return {
    }
  },

  computed: {
    socials() {
      let social = []

      this.data.social.map(function(value) {
        if (value.href) social.push(value);
      });

      return social

      // return social_links
    }
  },

  mounted() {
    if (localStorage['cookie:accepted']) {
      this.$ga.enable()
    } else {
      this.$ga.disable()
    }
  },

  methods: {
    enableTracking() {
      this.$ga.enable()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
