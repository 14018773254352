<template>
  <div id="memories">
    <h2>{{data.title}}</h2>
    <b-container>
      <b-row>
        <b-col v-for="memory in data.memories" :key="memory.key" @click="loadMemory(memory.key)" class="pointer">
          <center><b-img :src="memory.image" fluid rounded="circle" /></center>
          <h3>{{memory.title}}</h3>
          <nl2br tag="p" :text="memory.blurb" />
          <p class="read_more" v-if="memory.full_text" href="">Read More</p>
          <b-modal v-if="memory.full_text" :id="memory.key" :title="memory.title" size="lg" ok-only centered>
            <b-container>
              <b-row>
                <b-col cols="4">
                  <b-img :src="memory.image" fluid></b-img>
                </b-col>
                <b-col cols="8">
                  <nl2br tag="p" :text="memory.full_text" />
                </b-col>
              </b-row>
            </b-container>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Memories',

  props: ['data'],

  components: {
  },

  data () {
    return {
        title: "Remembering",
        text: "Test",
        image: "media/placeholder.jpg"
    }
  },

  methods: {
    loadMemory: function (key) {
      this.$bvModal.show(key)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
