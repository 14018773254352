<template>
  <div id="headline">
    <b-container>
      <b-row class="text-center" align-v="center" id="headline_banner">
        <b-col>
          <h1>{{data.title}}</h1>
          <div id="headline_blurb">
            <nl2br tag="p" class-name="lead" :text="data.blurb" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Headline',

  props: ['data'],

  data () {
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
