<template>
  <div v-if="dataLoad">
    <Header :data="data.header"></Header>
    <Headline :data="data.headline"></Headline>
    <b-container>
      <b-card-group deck class="py-5">
          <FindPage :data="data.find_page"></FindPage>
          <CreatePage :data="data.create_page"></CreatePage>
      </b-card-group>
    </b-container>
    <Help :data="data.help"></Help>
    <hr>
    <Impacts :data="data.impacts"></Impacts>
    <hr>
    <Memories :data="data.memories"></Memories>
    <Footer :data="data.footer"></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Headline from '@/components/Headline'
import FindPage from '@/components/FindPage'
import CreatePage from '@/components/CreatePage'
import Help from '@/components/Help'
import Impacts from '@/components/Impacts'
import Memories from '@/components/Memories'
import Footer from '@/components/Footer'

// import data from '@/config/data.json'

export default {
  name: 'Home',

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Home',
    meta: [
      { name: 'description', content: 'Tributes for your love one' },
      { property: 'og:description', content: 'Create a JustGiving Tribute Page for your love one, or find a tribute page to donate to' },
      { property: 'og:image', content: 'media/tribute.jpg' }
    ],
  },

  components: {
    Header,
    Headline,
    FindPage,
    CreatePage,
    Help,
    Impacts,
    Memories,
    Footer
  },

  data () {
    return {
      data: {},
      dataLoad: false
    }
  },

  async mounted() {
    var url = process.env.VUE_APP_DATA_URL
    const { data } = await this.$http.get(url);
    try {
      this.data = data
      this.dataLoad = true
    } catch (err) {
      return null
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
