<template>
    <b-card
      img-src="media/find-page.jpg"
      img-alt="Find a Tribute Page"
    >
      <b-form id="search" @submit.prevent="search" class="px-4">
        <b-input-group class="mb-1 mr-sm-1 mb-sm-0">
          <b-input v-model="query" :placeholder="data.search_placeholder"></b-input>
          <b-button variant="primary" type="submit">Search</b-button>
        </b-input-group>
      </b-form>
      <b-modal id="results" title="Search Results" size="lg" ok-only centered>
        <div v-if="loading" class="text-center">
          <h2>Loading... {{progress}}% <b-spinner type="grow" variant="primary" label="Spinning"></b-spinner></h2>
          <b-progress :value="progress" height="2rem" variant="primary" :max="progress_max" class="mb-3"></b-progress>
        </div>
        <div v-else-if="ready && results.length > 0">
          <b-table :items="results" :fields="fields">
            <template v-slot:cell(Logo)="data">
              <a :href="data.item.Link"><b-img class="circle-thumb" :src="data.item.Logo"  rounded="circle"></b-img></a>
            </template>
            <template v-slot:cell(Page)="data">
              <a :href="data.item.Link"><text-highlight :queries="query">{{data.item.IMO_Name || data.item.Name}}</text-highlight></a>
            </template>
            <template v-slot:cell(OwnerFullName)="data">
              <text-highlight :queries="query">{{data.value}}</text-highlight>
            </template>
          </b-table>
          <b-pagination
            v-if="pages_total > 1"
            v-model="page"
            :total-rows="pages_total"
            :per-page="1"
            align="center"
            @change="handlePageChange"
          ></b-pagination>
        </div>
        <div v-else>
          <p>No pages found under '{{this.query}}'.</p>
          <p>{{data.search_no_results}}</p>
        </div>
      </b-modal>
    </b-card>
</template>

<script>
export default {
  name: 'FindPage',
  props: ['data'],

  data () {
    return {
      query: '',
      loading: true,
      ready: false,
      progress: 0,
      progress_max: 100,

      fields: [
        {
          key: 'Logo',
          label: '',
          class: 'thumb'
        },
        {
          key: 'Page',
          label: 'In Memory Of'
        },
        {
          key: 'OwnerFullName',
          label: 'Created By'
        }
      ],

      results: [],

      results_total: 0,
      page: 1,
      pages_total: 1,
    }
  },

  methods: {
    async handlePageChange(page) {
      this.ready = false
      this.loading = true
      this.progress = 0
      await this.loadPage(page)
      this.loading = false
      this.ready = true
    },

    async loadName(id) {
      var url = process.env.VUE_APP_JG_API_URL + 'fundraising/pagebyid/' + id
      const { data } = await this.$http.get(url);
      this.progress += 10
      try {
        return data.rememberedPersonSummary.name
      } catch (err) {
        return null
      }
    },

    async loadPage(page) {
      var offset = (page - 1) * 10
      var url = process.env.VUE_APP_JG_API_URL + process.env.VUE_APP_JG_SEARCH_URL + this.query + '&offset=' + offset
      const { data } = await this.$http.get(url);
      if (data['Total']) {
        this.results_total = data['Total']
        this.pages_total = Math.ceil(data['Total'] / 10)
        this.results = data['GroupedResults'][0]['Results'];
        const newData = await Promise.all(this.results.map(({ Id }) => this.loadName(Id)));

        this.results = this.results.map((result, i) => ({
          ...result,
          IMO_Name: newData[i],
        }));
      } else {
        this.results = []
      }
    },

    async search() {
      this.$bvModal.show('results')
      this.handlePageChange(1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
