<template>
  <b-container id="help">
    <h2>{{data.title}}</h2>
    <div v-html="data.html"></div>
  </b-container>
</template>

<script>
export default {
  name: 'Help',

  props: ['data']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
